<template>
	<div class="app flex-row align-items-center justify-content-center">
		<b-card-group>
			<b-card no-body class="p-4">
				<b-card-body>
					<h1>Login</h1>
					<p class="text-muted">Sign In to your account</p>
					<vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
					<b-row>
						<b-col cols="6">
							<b-button variant="primary" class="px-4" @click="login">Login</b-button>
						</b-col>
						<b-col cols="6" class="text-right">
							<b-button variant="link" class="px-0">Forgot password?</b-button>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>
		</b-card-group>
	</div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator';
import auth from '../services/auth.service';
import * as links from '.././router/links';
import { loadLanguageTranslations } from '../store/actions';
import store from '../store/index';
import loading from '../mixins/loading.mixin';
import enums from '../services/helpers/enums';

export default {
	name: 'login-form',
	mixins: [loading],
	components: {
		'vue-form-generator': VueFormGenerator.component,
	},
	data() {
		return {
			model: {
				loginemail: '',
				password: '',
			},

			schema: {
				fields: [
					{
						type: 'input',
						inputType: 'email',
						label: 'Login',
						model: 'loginemail',
						placeholder: 'Your login',
						featured: true,
						required: true,
					},
					{
						type: 'input',
						inputType: 'password',
						label: 'Password',
						model: 'password',
					},
				],
			},
			formOptions: {
				validateAfterLoad: false,
				validateAfterChanged: true,
				validateAsync: true,
			},
		};
	},
	methods: {
		login: function() {
			auth.Login(this.model.loginemail, this.model.password).then(() => {
				store.dispatch(loadLanguageTranslations, 'sk');
				//to do: pre kazdu rolu ina vstupna stranka?
				if (auth.GetUser().RoleId === enums.role.CopyWriterSenior) {
					this.$router.push(links.seoPageTable);
				} 
				else if(auth.GetUser().RoleId === enums.role.CopyWriterJunior) {
					this.$router.push(links.attractionTable);
				} 
				else {
					this.$router.push(links.objectsTable);
				}

			});
		},
		enterHandler: function(event) {
			if (event.keyCode === 13) {
				this.login();
			}
		},
	},
	mounted() {
		this.stopLoading();
		window.addEventListener('keyup', this.enterHandler, false);
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.enterHandler, false);
	},
};
</script>
