var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app flex-row align-items-center justify-content-center" },
    [
      _c(
        "b-card-group",
        [
          _c(
            "b-card",
            { staticClass: "p-4", attrs: { "no-body": "" } },
            [
              _c(
                "b-card-body",
                [
                  _c("h1", [_vm._v("Login")]),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v("Sign In to your account"),
                  ]),
                  _c("vue-form-generator", {
                    attrs: {
                      schema: _vm.schema,
                      model: _vm.model,
                      options: _vm.formOptions,
                    },
                  }),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "px-4",
                              attrs: { variant: "primary" },
                              on: { click: _vm.login },
                            },
                            [_vm._v("Login")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "6" } },
                        [
                          _c(
                            "b-button",
                            { staticClass: "px-0", attrs: { variant: "link" } },
                            [_vm._v("Forgot password?")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }